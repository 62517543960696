import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import classnames from "classnames";
import { SECOND } from "src/constants";
import { ComponentWithClassName, VoidCallback } from "src/types/common";
import { Gift } from "src/types/gift";
import { MediaGift } from "src/types/richFragment/RichGiftEvent";
import GiftAnimationFactory, {
  GiftAnimationProps,
} from "src/ui/animations/GiftAnimationFactory";
import {
  getGiftsAlternativeDomainContentSupportEnabled,
  getMediaGiftGifDuration,
} from "state/abTests";
import { useMakeAlternativeDomainUrl } from "ui/hooks/useMakeAlternativeDomainUrl";
import styles from "./GiftOverlay.scss";

export interface GiftOverlayProps {
  components?: GiftAnimationProps["components"];
  gift?: Gift;
  hideAnimationDurationMs?: number;
  mediaGift?: MediaGift;
  onComplete?: VoidCallback;
  onHideComplete?: VoidCallback;
}

const GiftOverlay: ComponentWithClassName<GiftOverlayProps> = ({
  gift,
  onComplete,
  hideAnimationDurationMs = SECOND,
  onHideComplete,
  mediaGift = {},
  components,
  className,
}) => {
  const gifDuration = useSelector(getMediaGiftGifDuration);
  const [isHidden, setHidden] = useState(false);
  const containerStyle = useMemo(
    () =>
      ({
        "--translateX": "0",
        "--translateY": "0",
        "--animDuration": `${hideAnimationDurationMs / SECOND}s`,
      }) as React.CSSProperties,
    [hideAnimationDurationMs]
  );

  const handleComplete = useCallback(() => {
    setHidden(true);
    onComplete?.();
  }, [onComplete]);

  useEffect(() => {
    if (isHidden) {
      setHidden(false);
    }
  }, [gift]);

  useEffect(() => {
    if (!mediaGift.webpUrl) {
      return;
    }
    const durationMs = gifDuration * SECOND;
    const timerId = setTimeout(() => {
      handleComplete();
    }, durationMs);

    return () => {
      clearTimeout(timerId);
    };
  }, []);

  const makeAlternativeDomainUrl = useMakeAlternativeDomainUrl(
    getGiftsAlternativeDomainContentSupportEnabled
  );

  return (
    <div
      className={classnames([styles.root, className])}
      data-testid="gift-overlay"
    >
      <div
        className={classnames(styles.hideAnimationContainer, {
          active: isHidden,
        })}
        style={containerStyle}
        onAnimationEnd={onHideComplete}
      >
        {gift && (
          <GiftAnimationFactory
            gift={gift}
            onComplete={handleComplete}
            components={components}
          />
        )}

        {mediaGift.webpUrl && window.Modernizr.webpanimation && (
          <img
            src={makeAlternativeDomainUrl(mediaGift.webpUrl)}
            className={styles.gif}
          />
        )}
      </div>
    </div>
  );
};

export default GiftOverlay;
