import React, { useCallback, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import { emitEvent } from "@analytics/emit";
import { EventFields, EventNames } from "@analytics/enums";
import { ScreenViewReporter } from "@analytics/screenView/ScreenViewReportingContext";
import { sendGiftFromBc } from "api/gifts";
import { getGiftsAlternativeDomainContentSupportEnabled } from "state/abTests";
import { giftsCacheSelectors, viewerSessionSelectors } from "state/selectors";
import Button, { ButtonSize, ButtonVariant } from "ui/common/button/Button";
import sharedMessages from "ui/common/intl/sharedMessages";
import { useMakeAlternativeDomainUrl } from "ui/hooks/useMakeAlternativeDomainUrl";
import useUiAction from "ui/hooks/useUiAction";
import styles from "./GiftFromBroadcaster.scss";

const GiftFromBroadcaster = ({
  receiverId,
  giftId,
  message: defaultMessage,
  dismiss,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const intl = useIntl();
  const [message, setMessage] = useState(defaultMessage);
  const [isEdited, setIsEdited] = useState(false);
  const streamId = useSelector(viewerSessionSelectors.getStreamId);
  const gift = useSelector((state) =>
    giftsCacheSelectors.getGiftById(state, giftId)
  );
  const editActionParams = useMemo(
    () => ({
      [EventFields.PEER_ID]: receiverId,
      [EventFields.STREAM_ID]: streamId,
    }),
    [receiverId, streamId]
  );
  const sendActionParams = useMemo(
    () => ({
      [EventFields.PEER_ID]: receiverId,
      [EventFields.ITEM_TYPE]: isEdited ? "with_changes" : "without_changes",
      [EventFields.STREAM_ID]: streamId,
    }),
    [isEdited, streamId]
  );
  const useEditUiAction = useUiAction({
    target: "edit_message",
    additionalParams: editActionParams,
  });
  const onChange = useCallback(
    (event) => {
      !isEdited && setIsEdited(true);
      setMessage(event.target.value);
    },
    [isEdited]
  );
  const onSendClick = useCallback(() => {
    emitEvent(EventNames.SEND_GIFT, {
      [EventFields.PEER_ID]: receiverId,
      [EventFields.STREAM_ID]: streamId,
      [EventFields.GIFT_ID]: giftId,
      [EventFields.SOURCE]: 9,
      [EventFields.ONE_CLICK_PURCHASE]: 0,
    });
    sendGiftFromBc({
      giftId,
      accountId: receiverId,
      message,
    }).then(() => {
      enqueueSnackbar(
        intl.formatMessage(sharedMessages.giftFromBroadcasterSent),
        {
          autoHideDuration: 1500,
          variant: "success",
        }
      );
      dismiss();
    });
  }, [message]);
  const onSendClickUiAction = useUiAction({
    target: "send_message",
    callback: onSendClick,
    additionalParams: sendActionParams,
  });

  const makeAlternativeDomainUrl = useMakeAlternativeDomainUrl(
    getGiftsAlternativeDomainContentSupportEnabled
  );

  return (
    <>
      <ScreenViewReporter
        name="gift_from_bc"
        additionalParams={{
          [EventFields.PEER_ID]: receiverId,
          [EventFields.STREAM_ID]: streamId,
        }}
      />
      <div className={styles.info}>
        <FormattedMessage
          id="gift.from.bc.invite"
          defaultMessage="Send a FREE GIFT to invite"
        />
      </div>
      {gift && (
        <img
          src={makeAlternativeDomainUrl(gift.icon)}
          className={styles.giftIcon}
        />
      )}
      <div className={styles.messageWrapper}>
        <span className={styles.name}>
          <FormattedMessage
            id="chat.current-conversation.input-placeholder"
            defaultMessage="Message"
          />
        </span>
        <textarea
          value={message}
          className={styles.message}
          onClick={useEditUiAction}
          onChange={onChange}
        />
      </div>
      <Button
        size={ButtonSize.BIG_48}
        variant={ButtonVariant.PRIMARY}
        onClick={onSendClickUiAction}
        className={styles.button}
      >
        <FormattedMessage
          id="modal.gift-confirmation.cta"
          defaultMessage="Send"
        />
      </Button>
    </>
  );
};
GiftFromBroadcaster.propTypes = {
  receiverId: PropTypes.string.isRequired,
  giftId: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  dismiss: PropTypes.func.isRequired,
};
export default GiftFromBroadcaster;
