import React, { Ref, memo, useMemo, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import classnames from "classnames";
import { AvatarSize, VipLabel } from "src/enums";
import { HTMLDivProps, Nullable } from "src/types/common";
import { BasicProfile } from "src/types/profile/profile";
import useVipImage from "src/ui/hooks/useVipImage";
import isNonLinkProps, {
  LinkPropsWithInnerRef,
} from "src/utils/isNonLinkProps";
import { getAlternativeDomainContentSupportEnabled } from "state/abTests";
import { useLazyBackgroundImageMutable } from "ui/hooks/useLazyBackgroundImage";
import { useMakeAlternativeDomainUrl } from "ui/hooks/useMakeAlternativeDomainUrl";
import { linkToProfileBase } from "ui/navigation/links";
import { ReactComponent as DefaultAvatar } from "img/default_avatar.svg";
import styles from "./Avatar.scss";

interface BaseAvatarProps {
  accountId?: string;
  avatarImageClassName?: string;
  basicProfile?: BasicProfile;
  imageUrl?: string;
  lazy?: boolean;
  onMobGrid?: boolean;
  preferFullSize?: boolean;
  size?: AvatarSize;
  vipLabel?: Nullable<VipLabel>;
}

interface AvatarProps extends HTMLDivProps, BaseAvatarProps {
  innerRef?: Ref<HTMLDivElement>;
  to?: undefined;
}

interface LinkAvatarProps extends LinkPropsWithInnerRef, BaseAvatarProps {}

const Avatar: React.FC<AvatarProps | LinkAvatarProps> = (props) => {
  const {
    imageUrl,
    basicProfile,
    className,
    size = AvatarSize.SMALL,
    onMobGrid = false,
    preferFullSize = false,
    vipLabel,
    lazy = true,
    avatarImageClassName,
    children,
    accountId,
    ...rest
  } = props;

  const location = useLocation();
  const isAvatarDisabled =
    location.pathname.startsWith(linkToProfileBase) &&
    (accountId ? location.pathname.includes(accountId) : true);

  const makeAlternativeDomain = useMakeAlternativeDomainUrl(
    getAlternativeDomainContentSupportEnabled
  );

  const ref = useRef<Nullable<HTMLDivElement>>(null);
  const images = useMemo(
    () =>
      [
        imageUrl,
        ((preferFullSize || size === AvatarSize.LARGE) &&
          basicProfile?.profilePictureUrl) ||
          undefined,
        basicProfile?.profileThumbnailUrl,
      ]
        .filter((x) => x)
        .map((x) => makeAlternativeDomain(x)),
    [
      imageUrl,
      preferFullSize,
      size,
      basicProfile?.profilePictureUrl,
      basicProfile?.profileThumbnailUrl,
      makeAlternativeDomain,
    ]
  );

  const vipImage = makeAlternativeDomain(
    useVipImage({
      imageName: "avatar",
      vipLabel,
    })
  );

  const bgStyle = useMemo(() => {
    if (lazy) {
      return undefined;
    }

    return { backgroundImage: images.map((x) => `url("${x}")`).join(",") };
  }, [images, lazy]);
  useLazyBackgroundImageMutable(ref, images);

  const classes = useMemo(
    () =>
      classnames(styles.container, styles[size], className, {
        [styles.onMobGrid]: onMobGrid,
        [styles.clickable]: !!rest.onClick,
        [styles.disabled]: !rest.onClick && isAvatarDisabled,
      }),
    [size, className, onMobGrid, rest.onClick, isAvatarDisabled]
  );

  const content = (
    <>
      {vipLabel && vipLabel !== VipLabel.NONE && (
        <div
          className={styles.vipLabel}
          data-testid="avatar-vip-label"
          style={{ backgroundImage: `url(${vipImage})` }}
        />
      )}
      {images.length ? (
        <div
          ref={ref}
          className={classnames(
            styles.avatarImage,
            styles.round,
            avatarImageClassName
          )}
          style={bgStyle}
        />
      ) : (
        // we need this key in case of no images available after basic profile change
        // (react will remove data-lazybg attribute / bg-image style)
        <div
          ref={ref}
          key="default-avatar"
          className={classnames(styles.defaultAvatarContainer, styles.round)}
        >
          <DefaultAvatar className={styles.defaultAvatar} />
        </div>
      )}
    </>
  );

  if (isNonLinkProps(rest)) {
    const { innerRef: divInnerRef, ...divOtherProps } = rest;

    return (
      <div
        data-testid="avatar"
        className={classes}
        ref={divInnerRef}
        {...divOtherProps}
      >
        {content}
      </div>
    );
  }

  const { innerRef: linkInnerRef, ...linkOtherProps } = rest;

  return (
    <Link
      data-testid="avatar"
      className={classes}
      ref={linkInnerRef}
      {...linkOtherProps}
    >
      {content}
    </Link>
  );
};

export default memo(Avatar);
